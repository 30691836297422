@import '~normalize.css';
@import '~@zendeskgarden/css-bedrock/src/index.css';

$custom-blue: #0268ef;
$custom-blue-hover: #1c7fff;

// We have to override the color variable itself for this to work
// Overriding the $pt-intent-primary isn't effective
$blue3: $custom-blue;
$blue2: $custom-blue-hover;
// This is the mousedown button color
$blue1: $custom-blue;

@import 'theme/blueprintjs-svg-icon-overrides';
@import '~@blueprintjs/core/src/blueprint';

body,
html {
  background: #f5f7fa;
  width: auto;
  // This is the previously final calculated value from --zd-html--line-height
  // Prevents Global Warehouse dropdown link on landing page from closing when cursor leaves the header link
  line-height: 20px;
}

html.overflow-hidden {
  overflow: hidden !important;
}

#notif-banner {
  display: none;
}

#jsd-widget {
  opacity: 0;
  pointer-events: none;
}

body.dashboard #jsd-widget {
  opacity: 1;
  pointer-events: all;
}

html.overflow-hidden #jsd-widget {
  display: none !important;
}

body.dashboard.end-user #jsd-widget {
  display: none !important;
}

@media (max-width: 768px) {
  body #jsd-widget {
    display: none !important;
  }
}

.page-conversations .app-footer {
  display: none !important;
}
.page-conversations .dashboard-app-container {
  min-height: calc(100vh - 5px);
}

@media (max-width: 768px) {
  .page-conversations .sidebar-page {
    max-width: 100%;
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .page-details .dashboard-left-column {
    display: block !important;
  }
  .dashboard-left-column {
    display: none !important;
  }
}

body.termly-terms-hidden div[class*='termly-consent-banner-termly-banner-'] {
  opacity: 0 !important;
}

div[class*='termly-consent-banner-termly-banner-'] button {
  max-width: 120px !important;
}

@media (max-width: 1000px) {
  div[class*='termly-consent-banner-content-'] p {
    flex-direction: column;
  }

  div[class*='termly-consent-banner-btn-container-'] {
    margin-top: 20px;
  }
}

div[class*='termly-consent-banner-bottom-'] {
  padding: 14px !important;
  height: auto !important;
  min-height: 0 !important;
  background: #717070 !important;
}
div[class*='termly-consent-banner-bottom-'] *:not(button) {
  background: #717070 !important;
}
div[class*='termly-consent-banner-content-'] > p {
  max-width: 94% !important;
}
div[class*='termly-style-consent-banner-'] {
  width: 113px !important;
}

.banner-visible #notif-banner {
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: block;
}
.banner-visible #notif-banner ~ #root .app-header-comp,
.banner-visible #notif-banner ~ #root .sidebar-menu,
.banner-visible #notif-banner ~ #root .overlay-enter-done,
.banner-visible #notif-banner ~ #root .overlay-enter,
.banner-visible .public-app-container {
  margin-top: 100px;
}

@media (min-width: 768px) {
  .banner-visible #notif-banner ~ #root .sidebar-page {
    margin-top: 100px;
  }
}

svg {
  display: block;
}

p {
  margin-bottom: 0;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none !important;
}

.input-container {
  position: relative;
}
.input-container input {
  padding-right: 23px !important;
}

input::placeholder {
  color: #d2d2d2 !important;
  /*custom_placeholder*/
}

/*APP STYLED*/
.main-overflow-hidden {
  overflow: hidden;
  max-height: 100vh;
}

/*INPUT OVERRIDES*/
.text__c-txt__input___2ZeOl {
  border: 1px solid #eeeeee;
}

.index__col-1___fFKXq,
.index__col-2___3XV5O,
.index__col-3___jvNRw,
.index__col-4___3Mudj,
.index__col-5___1Zvc6,
.index__col-6___2QX_f,
.index__col-7___UUibC,
.index__col-8___2x_D6,
.index__col-9___31LRK,
.index__col-10___2808f,
.index__col-11___gGSq0,
.index__col-12___2hTZ7,
.index__col-auto___1ydX_,
.index__col-lg-1___2DuKx,
.index__col-lg-2___1qPOW,
.index__col-lg-3___1JqaG,
.index__col-lg-4___X-NS3,
.index__col-lg-5___3HKrf,
.index__col-lg-6___3jq_1,
.index__col-lg-7___3ejZ8,
.index__col-lg-8___3jgP4,
.index__col-lg-9___3FemR,
.index__col-lg-10___32zpW,
.index__col-lg-11___2mWJd,
.index__col-lg-12___wy3bv,
.index__col-lg-auto___YO2eW,
.index__col-lg___2voBo,
.index__col-md-1___19UVB,
.index__col-md-2___3RIDB,
.index__col-md-3___3-uJW,
.index__col-md-4___21alM,
.index__col-md-5___1YjDh,
.index__col-md-6___YSMTc,
.index__col-md-7___1NiZH,
.index__col-md-8___3_zSS,
.index__col-md-9___gCTVe,
.index__col-md-10___2F4Ec,
.index__col-md-11___1MdNE,
.index__col-md-12___2wpcO,
.index__col-md-auto___1ng37,
.index__col-md___1jOwo,
.index__col-sm-1___3QLfs,
.index__col-sm-2___3TciM,
.index__col-sm-3___31D7B,
.index__col-sm-4___3CDsx,
.index__col-sm-5___19_76,
.index__col-sm-6___1kaDN,
.index__col-sm-7___2jR0V,
.index__col-sm-8___IPp02,
.index__col-sm-9___2D2ku,
.index__col-sm-10___vW6Nh,
.index__col-sm-11___3GhS1,
.index__col-sm-12___28mx2,
.index__col-sm-auto___2TeyY,
.index__col-sm___3bawl,
.index__col-xl-1___2hFAE,
.index__col-xl-2___3o8Ji,
.index__col-xl-3___2O86l,
.index__col-xl-4___2H9_C,
.index__col-xl-5___1T1HI,
.index__col-xl-6___37Olm,
.index__col-xl-7___2-mm_,
.index__col-xl-8___1RX4g,
.index__col-xl-9___LQCEf,
.index__col-xl-10___qI7uM,
.index__col-xl-11___nE3oS,
.index__col-xl-12___2R9U8,
.index__col-xl-auto___2qdMe,
.index__col-xl___FW5Sw,
.index__col___MSTak {
  padding-right: 7px;
  padding-left: 7px;
}

.index__row___Ff4ac {
  margin-right: -7px;
  margin-left: -7px;
}

.index__container-fluid___1xN0x,
.index__container___3nUzJ {
  padding-right: 7px;
  padding-left: 7px;
}

.index__no-gutters___2Cpwn {
  margin-right: 0;
  margin-left: 0;
}

/*GOOGLE MAP OVERRIDE*/
#map-view
  > div
  > div.map-wrapper
  > div
  > div
  > div.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom
  > div
  > div {
  transform: scale(0.7);
}

body .mapboxgl-ctrl .mapboxgl-ctrl-attrib-inner a,
.mapboxgl-ctrl-logo {
  opacity: 0 !important;
}

/*TAB OVERRIDES*/
.tabs-style-override .index__c-tab__list___1DJ8w {
  border-bottom: none !important;
  margin: 0;
  margin-bottom: 25px;
}

.no-border {
  border: none !important;
}

/*OVERRIDE FROM GLOBAL CSS*/
body a * {
  cursor: pointer;
}
body a {
  color: #0168ef !important;
}
body a.bold,
span.bold {
  font-weight: 500 !important;
}
body a:hover,
body a:focus {
  color: #0168ef !important;
}

.active-sidebar-item button {
  background-color: #fff;
  box-shadow: '0px 4px 5px rgba(66,100,251,0.061)'; /*raisedValue*/
}

/*ROUTED MODEL STYLES*/
.full-modal-wrapper {
  will-change: auto;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
.routed-modal-enter {
  transform: translate3d(0, 100vh, 0);
  backface-visibility: hidden;
}
.routed-modal-enter.routed-modal-enter-active {
  transform: translate3d(0, 0, 0);
  transition: all 500ms 20ms ease;
  backface-visibility: hidden;
}
.routed-modal-exit {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.routed-modal-exit.routed-modal-exit-active {
  transform: translate3d(0, 100vh, 0);
  transition: all 500ms 20ms ease;
  backface-visibility: hidden;
}

.routed-modal-appear {
  transform: translate3d(0, 100vh, 0);
  backface-visibility: hidden;
}

.routed-modal-appear.routed-modal-appear-active {
  transform: translate3d(0, 0, 0);
  transition: all 500ms 1200ms ease;
  backface-visibility: hidden;
}

.ie-eleven .routed-modal-enter.routed-modal-enter-active {
  transform: translate3d(0, 100px, 0);
}
.ie-eleven .routed-modal-exit {
  transform: translate3d(0, 100px, 0);
}

.ie-eleven .routed-modal-appear.routed-modal-appear-active {
  transform: translate3d(0, 100px, 0);
}

/*CALENDAR STYLES*/
.react-date-picker__calendar-button:before {
  content: '\e936';
}
/*.react-calendar__navigation__next-button:before {
  content: "\e907";
  font-family: 'icomoon';
}
.react-calendar__navigation__prev-button:before {
  content: "\e906";
  font-family: 'icomoon';
}*/

.react-tel-input {
  border: none;
  border-radius: 0px;
  height: 41px;
}
.react-tel-input .flag-dropdown {
  border: none;
}
.react-tel-input .selected-flag {
  width: 46px;
  border: none;
  background: #fff;
  border-radius: 0px;
  border-bottom: 1px solid #cfcfcf;
}
.flag {
  background-position: -112px -143px;
}
.react-tel-input .arrow {
  left: 24px !important;
}

.react-tel-input .form-control {
  padding-left: 50px;
  border: none;
  border-bottom: 1px solid #cfcfcf;
  height: 41px;
  width: 100%;
  border-radius: 0;
  font-size: 14px;
  color: #3e3e3e !important;
  background: transparent;
}
.react-tel-input input,
.flag-dropdown {
  border: none;
}

/**/
/**/
/**/
/**/
/**/
/*CSS FONTS ICON*/
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?q679oh');
  src: url('../fonts/icomoon.eot?q679oh#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?q679oh') format('woff2'),
    url('../fonts/icomoon.ttf?q679oh') format('truetype'),
    url('../fonts/icomoon.woff?q679oh') format('woff'),
    url('../fonts/icomoon.svg?q679oh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'],
.react-date-picker__calendar-button,
.editable-cell:after,
.editable-header:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-phone:before {
  content: '\e955';
}
.icon-profile-approve:before {
  content: '\e953';
}
.icon-profile-decline:before {
  content: '\e954';
}

.icon-arrow-right-short:before {
  content: '\e94c';
}

.icon-share:before {
  content: '\e941';
}
.icon-cup:before {
  content: '\e946';
}
.icon-building:before {
  content: '\e947';
}
.icon-twitter:before {
  content: '\e950';
}
.icon-hyperlink:before {
  content: '\e951';
}
.icon-facebook:before {
  content: '\e952';
}
.icon-delivery-icon:before {
  content: '\e942';
}
.icon-data-icon-check:before {
  content: '\e94f';
}
.icon-checkbox-checked:before {
  content: '\e93e';
}
.icon-icon-compare:before {
  content: '\e94a';
}
.icon-database-system-2:before {
  content: '\e94b';
}
.icon-graph:before {
  content: '\e92b';
}
.icon-arrow-up:before {
  content: '\e900';
}
.icon-arrow-left:before {
  content: '\e901';
}
.icon-arrow-right:before {
  content: '\e902';
}
.icon-arrow-down:before {
  content: '\e903';
}
.icon-arrow-left-long:before {
  content: '\e948';
}
.icon-arrow-right-long:before {
  content: '\e949';
}
.icon-chevron-up:before {
  content: '\e904';
}
.icon-chevron-down:before {
  content: '\e905';
}
.icon-chevron-left:before {
  content: '\e906';
}
.icon-chevron-right:before {
  content: '\e907';
}
.icon-close:before {
  content: '\e908';
}
.icon-arrow-right-outside:before {
  content: '\e92e';
}
.icon-arrow-outside:before {
  content: '\e92f';
}
.icon-arrow-inside:before {
  content: '\e930';
}
.icon-arrow-inside-single:before {
  content: '\e931';
}
.icon-fileboard:before {
  content: '\e909';
}
.icon-database-system:before {
  content: '\e90a';
}
.icon-file-arrow-down:before {
  content: '\e90b';
}
.icon-email-:before {
  content: '\e938';
}
.icon-email:before {
  content: '\e938';
}
.icon-1573:before {
  content: '\e938';
}
.icon-email-fill:before {
  content: '\e932';
}
.icon-message:before {
  content: '\e90c';
}
.icon-information:before {
  content: '\e933';
}
.icon-menu:before {
  content: '\e90d';
}
.icon-grid-menu:before {
  content: '\e92d';
}
.icon-search-right:before {
  content: '\e90e';
}
.icon-search-left:before {
  content: '\e90f';
}
.icon-star:before {
  content: '\e910';
}
.icon-flag:before {
  content: '\e915';
}
.icon-delete:before {
  content: '\e934';
}
.icon-done:before {
  content: '\e911';
}
.icon-done-outline:before {
  content: '\e912';
}
.icon-edit:before,
.editable-cell:after,
.editable-header:after {
  content: '\e913';
}
.icon-window:before {
  content: '\e914';
}
.icon-download-2:before {
  content: '\e935';
}
.icon-circle-plus:before {
  content: '\e93a';
}
.icon-flag-filled:before {
  content: '\e93d';
}
.icon-grid:before {
  content: '\e916';
}
.icon-notification:before {
  content: '\e917';
}
.icon-settings-v:before {
  content: '\e918';
}
.icon-settings:before {
  content: '\e919';
}
.icon-profile:before {
  content: '\e91a';
}
.icon-profile-sharp:before {
  content: '\e91b';
}
.icon-calendar:before {
  content: '\e936';
}
.icon-chart:before {
  content: '\e91c';
}
.icon-bookmark-outline:before {
  content: '\e91d';
}
.icon-bookmark:before {
  content: '\e91e';
}
.icon-share-:before {
  content: '\e944';
}
.icon-1101:before {
  content: '\e944';
}
.icon-cloud-ok:before {
  content: '\e937';
}
.icon-cloud-upload:before {
  content: '\e93c';
}
.icon-align-text:before {
  content: '\e91f';
}
.icon-contract:before {
  content: '\e92a';
}
.icon-contract-2:before {
  content: '\e929';
}
.icon-eye:before {
  content: '\e93b';
}
.icon-map:before {
  content: '\e920';
}
.icon-pin-fill:before {
  content: '\e92c';
}
.icon-pin:before {
  content: '\e921';
}
.icon-exit:before {
  content: '\e922';
}
.icon-enter:before {
  content: '\e923';
}
.icon-download:before {
  content: '\e924';
}
.icon-expand:before {
  content: '\e925';
}
.icon-cup-:before {
  content: '\e945';
}
.icon-106:before {
  content: '\e945';
}
.icon-dice:before {
  content: '\e926';
}
.icon-building-:before {
  content: '\e943';
}
.icon-808:before {
  content: '\e943';
}
.icon-lock:before {
  content: '\e927';
}
.icon-lock-rounded-close-circle:before {
  content: '\e939';
}
.icon-lock-circle:before {
  content: '\e928';
}
.icon-arrow-right-short:before {
  content: '\e94c';
}
.icon-share:before {
  content: '\e941';
}
.icon-cup:before {
  content: '\e946';
}
.icon-building:before {
  content: '\e947';
}
.icon-settings-wheel:before {
  content: '\e94d';
}
.icon-arrow-left-short:before {
  content: '\e94e';
}
.icon-close-outline:before {
  content: '\e940';
}
.icon-check-outline:before {
  content: '\e93f';
}
.icon-warning:before {
  content: '\ea07';
}
.icon-checkmark:before {
  content: '\ea10';
}
.icon-file-pdf:before {
  content: '\eadf';
}
.icon-plus:before {
  content: '\ea0a';
}
.icon-minus:before {
  content: '\ea0b';
}

// AG Grid
.ag-row-disable-deselect-checkbox {
  .ag-cell .ag-cell-wrapper .ag-selection-checkbox .ag-checkbox .ag-checked {
    pointer-events: none;
  }
}
